body {
  margin: 0;
  font-size: 20px;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 13, 2022 */



@font-face {
    font-family: 'bb-text';
    src: url('fonts/gt-walsheim-pro-regular-webfont.woff2') format('woff2'),
         url('fonts/gt-walsheim-pro-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'bb-text';
    src: url('fonts/gt-walsheim-pro-medium-webfont.woff2') format('woff2'),
         url('fonts/gt-walsheim-pro-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}
@font-face {
    font-family: 'bb-text';
    src: url('fonts/gt-walsheim-pro-light-webfont.woff2') format('woff2'),
         url('fonts/gt-walsheim-pro-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}
.Chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.App {
  display: flex;
  font-family: "bb-text", sans-serif;
  height: 100vh;
  //Mobile
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse
    
  }
  

  .Chatbot {
      flex: 70%;
      display: flex;
    //Mobile
    @media only screen and (max-width: 1024px) {
      flex: calc(100% - 60px);
    }
    //Tablet
    @media only screen and (max-width: 1280px) and (min-width: 1025px) {
      
      flex: 60%;
    }
  }
  .Website {
      flex: 30%;
      padding: 100px 5vw 50px;
      //Tablet
      @media only screen and (max-width: 1280px) and (min-width: 1025px) {
        
        flex: 40%;
      }

      //Mobile
      @media only screen and (max-width: 1024px) {
        flex: 60px;
        padding: 0;
        .Content {
          display: none;
        }
        .Logo {
          position: relative;
          left: 0px;
          padding: 12px 20px 0;
          display: inline-block;
        }
        box-shadow: -20px 20px 20px rgb(21 130 226 / 20%), 20px 5px 30px rgba(226, 21, 164, 0.1);

        z-index: 10;
        
      }
      .Talk {
        position: absolute;
        top: 0;
        right: 40px;
        padding: 12px;
        display: none;
        transition: all .2s ease-in-out;
        &.pristine {
          right: 0px;
        }
        button {
          background-color: transparent;
          font-family: "bb-text", sans-serif;
          border: none;
          font-size: 18px;
          cursor: pointer;
          color: #1584E2;
          img {
            position: relative;
            top: 10px;
            margin-left: 5px;
            transition: all .5s ease-in-out;
          }
          
        }
        //Mobile
        @media only screen and (max-width: 1024px) {
          display: block;
        }
      }
      .Restart {
        position: absolute;
        top: 0;
        right: 0;
        padding: 30px;
        //Mobile
        @media only screen and (max-width: 1024px) {
            padding: 12px;
            button {
              font-size: 0 !important;
            }
        }
        button {
          background-color: transparent;
          font-family: "bb-text", sans-serif;
          border: none;
          font-size: 18px;
          cursor: pointer;
          color: #1584E2;
          img {
            position: relative;
            top: 10px;
            margin-left: 5px;
            transition: all .5s ease-in-out;
          }
          &:hover {
            text-decoration: underline;
            img {
              transform: rotate(-360deg);
            }
          }
        }
      }
      .Logo {
        position: relative;
        left: -14px;
      }
      h1 {
        font-size: 3em;
        line-height: 1;
        margin-bottom: 0.1em;
        margin-top: 0;
      }
  }
}

h1, h2, h3 {
  font-weight: 300;
}
p {
  font-weight: 400;
}

button.Button {
  display: inline-block;
  height: 55px;
  border: 2px solid #1584E2;
  background-color: #FFF;
  font-family: "bb-text", sans-serif;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #1584E2;
  padding: 0 30px;
  cursor: pointer;
  margin-bottom: 5px;
  line-height: 52px;
  text-decoration: none;
  transition: all .5s ease-in-out;
  &.main {
    background-color: #1584E2;
    color:#FFF;
    &:hover {
      background-color: #FFF;
      color: #021E4C;
      border: 2px solid #021E4C;
    }
  }

  &:hover {
    
    color: #021E4C;
    border: 2px solid #021E4C;
  }
}

a.Button {
  display: inline-block;
  transition: all .5s ease-in-out;
  height: 55px;
  border: 2px solid #1584E2;
  background-color: #FFF;
  font-family: "bb-text", sans-serif;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #1584E2;
  padding: 0 30px;
  cursor: pointer;
  margin-bottom: 5px;
  line-height: 55px;
  text-decoration: none;
  &:hover {
    
    color: #021E4C;
    border: 2px solid #021E4C;
  }
}

