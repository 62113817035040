.MyLandbot {
  min-height: 0;
 flex: 1;
 border: 1px solid rgba(0, 0, 0, 0.2);
 border-radius: 40px;
 overflow: hidden;
 margin: 20px;
 position: relative;
 min-width: 100%;

  //Mobile
  @media only screen and (max-width: 1024px) {
    margin: 0;
    border-radius: 0;
    border: 0;
  }

}
